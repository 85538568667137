const stories = [
    {
        image: 'stories/Image-1.png',
        description: '<span class="font f-w-800 f-s-24 l-h-32" style="color: #141414;">Wise Snack <span style="color: #0E47C0;">saved 70%</span> on product development cost for a new innovation</span>',
        uri: '/',
        alt: 'Wise Snack',
    },
    {
        image: 'stories/Image-2.png',
        description: '<span class="font f-w-800 f-s-24 l-h-32" style="color: #141414;">KIND  launched a new product with <span style="color: #009B8E;">6x return</span> on investment</span>',
        uri: '/',
        alt: 'KIND',
    },
    {
        image: 'stories/Image-3.png',
        description: '<span class="font f-w-800 f-s-24 l-h-32" style="color: #141414;">JUST EGG increased  their store velocity <span style="color: #F78834;">by 80%</span></span>',
        uri: '/',
        alt: 'JUST EGG',
    },
];

export default stories;
