<template>
    <div
        v-if="hasValue"
        class="d-flex flex-column p-y-48 p-x-48 p-x-small-16 p-y-small-40"
        style="background-color: #F4F4F4; gap: 40px;"
        :style="{ 'border-radius': isMobile ? '0px' : '60px' }"
    >
        <div
            class="d-flex flex-column"
            style="gap: 14px;"
        >
            <span class="font Roboto f-w-700 f-s-44 l-h-48">Our Customers Stories</span>
        </div>

        <div class="stories">
            <CustomerStoriesItem
                class="story"
                v-for="story in stories"
                :key="story.title"
                :story="story"
            />
        </div>
    </div>
</template>

<script>
import screen from '@/plugins/screen';
import CustomerStoriesItem from './CustomerStoriesItem.vue';

export default {
    name: 'CustomerStories',
    components: {
        CustomerStoriesItem,
    },
    mixins: [screen],
    props: {
        stories: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        hasValue() {
            return this.stories.length > 0;
        },
    },
};
</script>

<style scoped>
.stories {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
}

@media (max-width: 768px) {
    .stories {
        grid-template-columns: 100%;
    }
}
</style>
