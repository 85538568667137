<template>
    <div style="background-color: #FFFFFF; overflow: hidden; border-radius: 24px;">
        <slot name="default" :story="story">
            <img :src="$image(story.image)" :alt="story.alt" class="w-100">
            <div class="d-flex flex-column justify-content-between align-items-start flex-grow-1 p-x-24 p-y-24" style="gap: 32px;">
                <span v-html="story.description" />
            </div>
        </slot>
    </div>
</template>

<script>

export default {
    name: 'CustomerStoriesItem',
    props: {
        story: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
