const webPageSchema = {
    '@context': 'https://schema.org',
    '@graph': [
        {
            '@type': 'WebSite',
            '@id': 'http://peekage.com',
            url: 'http://peekage.com/',
            name: 'Digital Product Sampling Platform | Peekage',
        },
        {
            '@type': 'ImageObject',
            url: 'https://peekage.com/opengraph.png',
            width: 1200,
            height: 600,
        },
        {
            '@type': 'WebPage',
            '@id': 'https://peekage.com/faq',
            url: 'https://peekage.com/faq',
            inLanguage: 'en-US',
            name: 'Frequently asked questions (FAQ)',
        },
        {
            '@type': 'WebPage',
            '@id': 'https://peekage.com/contact',
            url: 'https://peekage.com/contact',
            inLanguage: 'en-US',
            name: "We'd love to hear from you.",
        },
        {
            '@type': 'WebPage',
            '@id': 'https://peekage.com/terms',
            url: 'https://peekage.com/terms',
            inLanguage: 'en-US',
            name: 'Terms & Conditions',
        },
        {
            '@type': 'WebPage',
            '@id': 'https://peekage.com/about',
            url: 'https://peekage.com/about',
            inLanguage: 'en-US',
            name: 'About Peekage.',
        },
        {
            '@type': 'WebPage',
            '@id': 'https://peekage.com/privacy',
            url: 'https://peekage.com/privacy',
            inLanguage: 'en-US',
            name: 'Privacy Policy',
        },
    ],
};

export {
    webPageSchema,
};
