const domain = 'https://peekage.com';

const breadcrumb = (currentPage) => {
    const p = [
        {
            uri: '',
            name: 'Peekage',
        },
    ];

    if (currentPage) p.push(currentPage);

    return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: p.map((page, index) => {
            return {
                '@type': 'ListItem',
                position: (index + 1),
                name: page.name,
                item: `${domain}${page.uri}`,
            };
        }),
    };
};

export {
    breadcrumb,
};
