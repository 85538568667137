<template>
    <div class="empower-page p-y-48 p-y-small-80">
        <div
            class="d-flex m-b-28"
            :class="{
                'flex-column justify-content-start align-items-start p-x-16': isMobile,
                'flex-row justify-content-between align-items-center': !isMobile
            }"
        >
            <Heading
                color="primary"
                class="text-start f-w-700"
                tag="p"
                size="web-md"
                style="letter-spacing: -0.5px;"
            >
                Your Product Needs
            </Heading>

            <nuxt-link
                to="contact?talk-to-an-expert=true"
                class="btn secondary md font f-s-16 l-h-20 f-w-700 d-inline-flex justify-content-start"
            >
                Try Peekage
                <span class="icon arrow-right size sm m-l-4" />
            </nuxt-link>
        </div>

        <div class="empower-cards">
            <SolutionCard
                class="card-item"
                v-for="(solution, i) in solutions"
                :key="i"
                :solution="solution"
                :is-vertical="isMobile"
                :to="solution.uri"
            />
        </div>
    </div>
</template>

<script>
import SolutionCard from '~/components/common/empower/empower-card';
import screen from '@/plugins/screen';

const solutions = [
    {
        image: 'arts/Pictogram-lg.svg',
        title: 'Consumer Insights',
        description: 'Ensure successful product launches and fuel future growth by quickly understanding your buyer persona, refining product positioning, messaging, and gauging demand and performance.',
        uri: '/consumer-insights-company',
    },
    {
        image: 'arts/Opt-in-lg.svg',
        title: 'In-home Product Testing',
        description: 'Build products that consumers love by testing your product concepts, formula, taste, packaging and pricing through out  your production at scale.',
        uri: '/consumer-insights-company',
    },
    {
        image: 'arts/Pictogram-2.svg',
        title: 'Digital Product Sampling',
        description: 'Run targeted at-home product trial campaigns to increase brand awareness, boost sales and collect product reviews.',
        uri: '/product-sampling-program',
    },
    {
        image: 'arts/Data-01.svg',
        title: 'Increase Retail Velocity',
        description: 'Support new product launches or key retailers through geo-targeted digital sampling programs.',
        uri: '/product-sampling-program',
    },
];

export default {
    components: {
        SolutionCard,
    },
    mixins: [screen],
    data() {
        return {
            solutions: [...solutions],
        };
    },
};
</script>

<style lang="scss" scoped>
.empower-page {
    .empower-cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }

    .card-item {
        display: block;
        height: 230px;
    }

    @media (max-width: 768px) {
        .empower-cards {
            grid-template-columns: 100%;
        }

        .card-item {
            display: block;
            height: auto;
            margin: 0 16px;
        }
    }
}
</style>
