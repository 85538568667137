<template>
    <div class="why-peekage p-y-small-48">
        <div class="container p-0 p-x-small-16">
            <div class="m-b-48">
                <Heading
                    color="primary"
                    class="f-w-700 m-t-8 m-b-24"
                    tag="p"
                    size="xxx-lg"
                >
                    Why Peekage?
                </Heading>
            </div>

            <div class="why-peekage-items">
                <div class="why-peekage-item" v-for="(item, i) in items" :key="i">
                    <div class="why-peekage-image m-r-12">
                        <img alt="product sampling company"
                             :src="$image(item.image)"
                             width="32"
                        >
                    </div>
                    <div>
                        <span class="d-block font f-s-20 l-h-28 f-w-800 m-b-4">{{ item.title }}</span>
                        <span class="font f-s-16 l-h-24 f-w-400">{{ item.content }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const items = [
    {
        title: 'Diverse community of shoppers',
        image: 'arts/Persons.svg',
        content: 'Sample your products to a community of qualified and representative shoppers.',
    },
    {
        title: 'Retail Foot Traffic',
        image: 'arts/Collect Data.svg',
        content: 'Drive retail velocity at specific locations with our geo-targeting capabilities.',
    },
    {
        title: 'Lightning Speed',
        image: 'arts/Speed.svg',
        content: 'Receive live consumer data on your dashboard in a matter of days, not weeks.',
    },
    {
        title: '1st Party Consumer Network',
        image: 'arts/Fulfilment.svg',
        content: 'Access to 5M+ diverse and engaged consumers across North America.',
    },
    {
        title: 'Targeting and Segmentation',
        image: 'arts/Retarget.svg',
        content: ' Leverage 200+ shoppers’ attributes and consumption habits to segment and target the ideal audience.',
    },
    {
        title: 'Affordable',
        image: 'arts/Affordable.svg',
        content: 'With one subscription all teams get big-market-research-firm quality at a fraction of the cost.',
    },
    {
        title: 'One Platform for all testing needs',
        image: 'arts/Opt-in-lg.svg',
        content: 'Peekage is an all-in-one solution for product testing. From in-home usage testing for detailed consumer insights during and after product development, to digital product sampling aimed at boosting sales and driving growth, everything is under one roof.',
    },
    {
        title: 'Simpler to Use',
        image: 'arts/online-experience-lg.svg',
        content: 'No need to have a data scientist in-house. Simply use our AI to generate a fully optimized study and obtain actionable insights effortlessly.',
    },
];
export default {
    props: {
        items: {
            type: Array,
            default: () => ([...items]),
        },
    },
    // data() {
    //     return {
    //         items: [...items],
    //     };
    // },
};
</script>

<style lang="scss" scoped>
.why-peekage {
    background-color: #F6F6F6;
    padding: 96px 0;

    &-items {
        display: flex;
        flex-wrap: wrap;
        margin: -18px;
    }

    &-item {
        display: flex;
        width: 100%;
        max-width: 550px;
        margin: 18px;
    }

    &-image {
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        min-width: 64px;
        min-height: 64px;
        height: 64px;
        width: 64px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

    }
}
</style>
