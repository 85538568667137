<template>
    <component :is="tag" :to="to" class="text-decoration-none">
        <div
            class="solution-card h-100"
            style="gap: 16px;"
            :style="{ '--hover-color': hoverColor}"
            :class="{
                'd-flex justify-content-center align-items-center': !isVertical,
                'd-flex flex-column': isVertical
            }"
        >
            <img
                alt="product sampling company"
                :src="$image(solution.image)"
                width="52"
                height="52"
                class="card-image"
            >

            <div class="d-flex flex-column">
                <Heading
                    color="primary"
                    class="solution-card-title d-block f-w-600 m-b-4"
                    tag="span"
                    size="x-sm"
                    style="letter-spacing: -0.75px; white-space: wrap;"
                >
                    {{ solution.title }}
                </Heading>

                <Texture
                    color="secondary"
                    class="solution-card-description f-w-400 m-b-20"
                    tag="p"
                    size="md"
                >
                    {{ solution.description }}
                </Texture>

                <span
                    v-if="to"
                    class="font f-w-500 f-s-14 l-h-16"
                    style="text-decoration: underline; color: #009B8E;"
                >
                    Learn more
                </span>
            </div>
        </div>
    </component>
</template>

<script>
export default {
    props: {
        solution: {
            type: Object,
            default: () => ({}),
        },
        isVertical: {
            type: Boolean,
            default: true,
        },
        to: {
            type: String,
            default: '',
        },
    },
    computed: {
        tag() {
            return this.to ? 'nuxt-link' : 'div';
        },
        hoverColor() {
            return this.to ? '#141414' : '#cbcbcb';
        },
    },
};
</script>

<style lang="scss" scoped>
.solution-card {
    padding: 32px;
    border: 1px solid #cbcbcb;
    box-sizing: border-box;
    border-radius: 16px;

    &:hover,
    &:focus {
        border: 1px solid var(--hover-color);
    }

    &-title {
        letter-spacing: -0.75px;
        min-width: 198px;
    }

    &-description {
        white-space: normal;
    }

    //    @media only screen and (min-width: 768px) {
    //        /* For desktop: */
    //        width: 279px;
    //        margin: 8px 8px;
    //    }
}
</style>
