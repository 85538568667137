const features = [
    {
        id: 1,
        title: 'Increase brand awareness',
        content:
            'Leverage our network of 4M+ users to promote your brand and products through highly-targeted product trials. Peekage amplifies your outreach efforts by connecting your brand to a highly engaged user segment.',
        image: 'pages/home/features/increase-brand-awareness-2023.jpg',
        class: '',
        aos: {
            content: 'fade-in',
            image: 'fade-in',
        },
        component: 'FeatureItem',
    },
    {
        id: 2,
        title: 'Audience segmentation',
        content:
            'Get in front of the consumers who fit your buyer’s persona and build the perfect audience by filtering customers with over 200+ attributes— from lifestyles and shopping habits to diets and everything in-between. Not sure who your perfect audience is yet? Conduct selective A/B tests through our digital product sampling trials to identify your customer’s attributes and create the ideal buyer persona.',
        image: 'pages/home/features/audience-segmentation.jpg',
        component: 'FeatureItem',
        class: 'nth-reverse',
        aos: {
            content: 'fade-in',
            image: 'fade-in',
        },
    },
    {
        id: 3,
        title: 'Boost sales and increase conversions',
        content:
            'By connecting your brand to qualified leads, Peekage helps you maintain consistent engagement with consumers through a variety of methods — from post-purchase follow-ups and incentives to retargeting campaigns.',
        image:
            'pages/home/features/boost-sales-and-increase-conversions-2023.jpg',
        component: 'FeatureItem',
        class: '',
        aos: {
            content: 'fade-in',
            image: 'fade-in',
        },
    },
    {
        id: 4,
        title: 'Actionable insights',
        content:
            'Our platform conducts targeted surveys and analytics on customers who have tested your product or service. We gather meaningful feedback and help you discover actionable insights that inform strategy, messaging, product positioning, and more.',
        image: 'pages/home/features/actionable-insights.jpg',
        component: 'FeatureItem',
        class: 'nth-reverse',
        aos: {
            content: 'fade-in',
            image: 'fade-in',
        },
    },
    {
        id: 5,
        title: 'Interested consumers',
        content:
            'Through our gamified app, consumers earn Peekage tokens and build their shopper’s profile by answering short surveys. The results of these surveys unlock personalized offers that reflect the consumer’s interests. And they exchange their tokens to redeem the offers that they are interested in. This eliminates product waste and increases both conversions and ROI.',
        image: 'pages/home/features/interested-consumers.jpg',
        component: 'FeatureItem',
        aos: {
            content: 'fade-in',
            image: 'fade-in',
        },
    },
];

export default features;
