<template>
    <div
        class="d-flex flex-column"
        style="gap: 8px"
    >
        <div class="d-flex" :class="{'flex-column' : isMobile}">
            <Heading
                color="primary"
                class="f-w-700 quoter-profile-detail m-b-small-4"
                tag="span"
                size="web-xx-sm"
                style="letter-spacing: -0.5px;"
            >
                {{ name }}
            </Heading>
            <span class="m-r-2" v-if="!isMobile">,</span>
            <Texture
                type="label"
                size="lg"
                color="primary"
                class="quoter-profile-detail f-w-500 m-b-small-4"
            >
                {{ position }}
            </Texture>
        </div>
        <Texture
            type="label"
            size="lg"
            color="primary"
            class="f-w-700"
        >
            {{ corp }}
        </Texture>
    </div>
</template>

<script>
import { get } from 'lodash';
import screen from '@/plugins/screen';

export default {
    name: 'TestimonialUserInfo',
    mixins: [screen],
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        name() {
            return get(this.item, 'details.name', '');
        },
        position() {
            return get(this.item, 'details.position', '');
        },
        corp() {
            return get(this.item, 'details.corp', '');
        },
    },
};
</script>
