import quotes from './quote';
import features from './features';
import featuresV2 from './featuresV2';
import stories from './stories';

export default {
    quotes,
    features,
    featuresV2,
    stories,
};
