<template>
    <div class="hero" :class="heroClass">
        <div class="hero-content" :class="heroContentClass">
            <slot name="header" :heading-text="headingText">
                <Heading
                    color="primary"
                    class="f-w-700 m-b-24"
                    tag="h2"
                    size="web-xxx-lg"
                    style="letter-spacing: -1px;"
                >
                    {{ headingText }}
                </Heading>
            </slot>

            <slot name="content" />

            <template v-if="!hideButton">
                <nuxt-link hreflang="en-ca" :to="uri" class="fit-content btn lg primary d-flex font f-s-16 l-h-24 f-w-700" style="letter-spacing: -0.3px;">
                    {{ btnText }}
                    <span class="icon arrow-right color onColor size sm m-l-4" />
                </nuxt-link>
            </template>
        </div>
        <div class="hero-image" :style="{ width: imageWidth }">
            <div class="hero-image-container" :style="imageStyles">
                <img alt="product sampling" class="opacity-0" :class="imageClass" :src="$image(image)" :style="{ width: imageWidth, height: imageHeight }">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        heroClass: {
            type: String,
            default: 'p-b-small-64',
        },
        hideButton: {
            type: Boolean,
            default: false,
        },
        heroContentClass: {
            type: String,
            default: 'p-y-80 p-y-small-64 p-b-small-10',
        },
        headingText: {
            type: String,
            default: 'Boost sales through targeted in-home product sampling',
        },
        image: {
            type: String,
            default: 'new/main-home.png',
        },
        imageWidth: {
            type: String,
            default: '464px',
        },
        imageHeight: {
            type: String,
            default: '568px',
        },
        imageClass: {
            type: [String, Object],
            default: 'w-100 h-100',
        },
        imageStyle: {
            type: Object,
            default: () => ({}),
        },
        uri: {
            type: String,
            default: 'contact?talk-to-an-expert=true',
        },
        btnText: {
            type: String,
            default: 'Talk to a sampling expert',
        },
    },
    computed: {
        imageStyles() {
            return Object.assign({}, {
                backgroundImage: `url(${this.$image(this.image)})`,
            }, this.imageStyle);
        },
    },
};
</script>

<style lang="scss" scoped>
.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    gap: 36px;

    &-reverse {
        flex-flow: column-reverse !important;
    }

    &-content {
        max-width: 100%;
    }

    &-image {
        &-container {
            width: 512px;
            height: 100%;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }

    @media (max-width: 768px) {
        &-image {
            &-container {
                width: 100%;
            }
        }
    }
    @media only screen and (min-width: 992px) {
        &-content {
            max-width: 698px;
        }
        flex-flow: inherit;
        // height: 568px;
        justify-content: space-between;
    }
}
</style>
