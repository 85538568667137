<template>
    <div class="d-flex flex-column p-x-64 p-y-24 p-x-small-28 p-y-small-0" v-if="hasValue">
        <div
            class="p-24"
            style="background-color: #F8F6F2; padding: 24px; border-radius: 16px;"
        >
            <transition name="fade" mode="out-in">
                <span
                    class="font f-w-600 f-s-24 l-h-32 f-w-small-700 f-s-small-18 l-h-small-28"
                    v-if="selectedItem"
                    style="letter-spacing: -0.75px;"
                    :key="selectedItem.content"
                >
                    {{ selectedItem.content }}
                </span>
            </transition>
        </div>

        <div class="d-flex flex-column" style="gap: 28px">
            <div class="d-block position-relative">
                <img
                    class="position-absolute"
                    style="transition: left 0.5s ease;"
                    :style="{ left: `${chevronLeft}px` }"
                    width="28px"
                    height="12px"
                    :src="$image('chevron-down-fill.svg')"
                >
            </div>
            <div class="d-flex align-items-center position-relative" style="gap: 16px">
                <span
                    v-if="hasNavigation"
                    class="icon chevron-left pointer"
                    @click="previous"
                />
                <div
                    class="d-flex justify-content-end flex-grow-1"
                    style="gap: 16px"
                >
                    <div
                        class="d-flex align-items-center pointer"
                        style="gap: 16px; transition: flex-grow 0.5s ease;"
                        :class="{ 'flex-grow-1': isActive(index) }"
                        v-for="(item, index) in items"
                        :key="item.id"
                        @click="selectedIndex = index"
                        :ref="`item-${index}`"
                    >
                        <img
                            style="border-radius: 100%; transition: border 0.3s ease;"
                            :style="{
                                border: `${isActive(index) ? 4 : 1}px solid #FF4F66`,
                                width: `${isMobile ? 64 : 80}px`,
                            }"
                            :src="$image(item.image)"
                        >
                        <TestimonialUserInfo
                            :item="item"
                            v-if="isActive(index) && !isMobile"
                        />
                    </div>
                </div>
                <span
                    v-if="hasNavigation"
                    class="icon chevron-right pointer"
                    @click="next"
                />
            </div>
            <TestimonialUserInfo
                :item="selectedItem"
                v-if="isMobile"
            />
        </div>
    </div>
</template>

<script>
import get from 'lodash/get';
import screen from '@/plugins/screen';
import TestimonialUserInfo from '~/components/common/testimonials/TestimonialUserInfo';

export default {
    components: {
        TestimonialUserInfo,
    },
    mixins: [screen],
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            selectedIndex: null,
            chevronLeft: 0,
            timer: null,
            progress: 0,
        };
    },
    computed: {
        selectedItem() {
            return this.items[this.selectedIndex];
        },
        hasNavigation() {
            return !this.isMobile && this.items.length > 1;
        },
        hasValue() {
            return this.items.length > 0;
        },
    },
    watch: {
        selectedIndex() {
            clearInterval(this.timer);
            this.changeChevronLeft();
            this.progress = 0;
            this.startTimer();
        },
        isMobile() {
            this.changeChevronLeft();
        },
    },
    mounted() {
        this.selectedIndex = 0;
    },
    methods: {
        startTimer() {
            this.timer = setInterval(() => {
                if (this.progress < 100) {
                    this.progress += 1;
                } else {
                    clearInterval(this.timer);
                    setTimeout(() => {
                        this.next();
                    }, 500);
                }
            }, 100);
        },
        changeChevronLeft() {
            setTimeout(() => {
                const element = this.$refs[`item-${this.selectedIndex}`];
                const offsetLeft = get(element, '[0].offsetLeft') || 0;
                this.chevronLeft = offsetLeft + (this.isMobile ? 16 : 26);
            }, 500);
        },
        isActive(index) {
            return index === this.selectedIndex;
        },
        next() {
            this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
        },
        previous() {
            this.selectedIndex = this.selectedIndex > 0
                ? this.selectedIndex - 1
                : this.items.length - 1;
        },
    },
};
</script>

<style>
.user-container {
    display: flex;
    flex-flow: start;
    align-items: start;
}

@media (max-width: 768px) {
    .user-container {
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }
}

.fade-enter-active {
    transition: all 0.3s ease;
}

.fade-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.3, 0.5, 1);
}

.fade-enter,
.fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}
</style>
