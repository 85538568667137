<template>
    <div class="m-b-60">
        <nuxt-link
            hreflang="en-ca"
            :to="uri"
            class="text-decoration-none"
        >
            <BoxGradient
                class="w-100"
                style="min-height: 280px"
                :btn-text="btnText"
                :is-primary="false"
            >
                <template #content>
                    <slot name="content" />
                </template>
            </BoxGradient>
        </nuxt-link>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: 'Find out how peekage can exceed your business goals',
        },
        uri: {
            type: String,
            default: 'contact?talk-to-an-expert=true',
        },
        btnText: {
            type: String,
            default: 'Book a demo',
        },
    },
};
</script>
