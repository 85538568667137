const quotes = [
    {
        id: 1,
        content:
            '“The team at Peekage works closely with the brand team to effectively drive trials at your key retail locations through their digital sampling platform. They seemed invested in making sure our program achieved our desired outcome.”',
        component: 'Quote',
        image: 'testimonials/Image-4.png',
        details: {
            name: 'Theresa Sarna',
            position: 'Senior Marketing Manager ',
            corp: 'Bubbies Mochi Ice Cream',
        },
    },
    {
        id: 2,
        content:
            '“Before Peekage, we were using an in-home product testing vendor that recruited study participants in the traditional, panel market research way. We switched to Peekage for their tech-forward approach to product research, especially advanced targeting and sophisticated analysis capabilities. We can conduct larger scale studies with Peekage with more complex segmentation and get richer data back.”',
        component: 'Quote',
        image: 'testimonials/Image-7.png',
        details: {
            name: 'Hannah Cui',
            position: 'Director of Platform',
            corp: 'Starday Foods',
        },
    },
    {
        id: 3,
        content: '“With Peekage we were able to move many units off grocery store shelves. And at the same time, we learned much more about customers who purchase us in-store. Peekage encouraged brand discovery by providing offers to customers that align with their shopping habits and what they’re interested in.”',
        component: 'Quote',
        image: 'testimonials/Image-2.png',
        details: {
            name: 'Jill Copnick',
            position: 'Senior Marketing Manager',
            corp: 'Fody Food',
        },
    },
    {
        id: 4,
        content:
            '“Peekage helped us to generate exceptional product reviews and testimonials for 30+ new product launches. This has exceeded our expectations with the easy delivery and format. We were also able to market to new customers with Peekage’s sampling capabilities.”',
        component: 'Quote',
        image: 'testimonials/Image-3.png',
        details: {
            name: 'Lynn Tran',
            position: 'Innovation E-commerce Lead',
            corp: 'Swanson Vitamins',
        },
    },
];

export default quotes;
