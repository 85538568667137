const features = [
    {
        id: 1,
        title: 'Grow your customer base quickly',
        headline: 'Targeted digital product sampling',
        content: 'Leverage our network of 5M+ diverse consumers to promote your brand and products through highly targeted product trials. Peekage amplifies your marketing efforts by sampling your products to a highly engaged community of diverse shoppers, enabling you to quickly increase awareness, and sales and collect reviews.',
        image: 'new/product-feature1.jpg',
        class: '',
        aos: {
            content: 'fade-in',
            image: 'fade-in',
        },
        component: 'FeatureItem',
        url: '/product-sampling-program',
        buttonTitle: 'Learn about Peekage product sampling program',
    },
    {
        id: 2,
        title: 'Develop products consumers love',
        headline: 'In-Home Product Testing & Insights',
        content: 'Build winning products by involving shoppers and leveraging data from the outset. Utilize variety of testings such as blind testing, sensory testing, efficacy testing, price testing, packaging testing, and formula refinement on your target audience to perfect your products in just a few iterations. Peekage in-home product testing decreases your time to market, ensures product market fit, and increases “share of wallet”.',
        image: 'insights/feature2.jpg',
        component: 'FeatureItem',
        class: 'nth-reverse',
        aos: {
            content: 'fade-in',
            image: 'fade-in',
        },
        url: '/consumer-insights-company',
        buttonTitle: 'Learn about Peekage In-home product testing',
    },
    {
        id: 3,
        title: 'Exclusive community of high value consumers',
        headline: 'Diverse Community of Shoppers',
        content: 'Peekage has a community of vibrant, highly engaged shoppers who join Peekage to test new products and innovations. They love to find their next favorite product or test products before hitting the market. They provide insights into their shopping habits, dietary preferences, interests, routines, and more with you. When matched with your product, they review the products, share their feedback, and help you increase awareness and sales for your products.',
        image: 'insights/1.jpg',
        component: 'FeatureItem',
        aos: {
            content: 'fade-in',
            image: 'fade-in',
        },
        url: '/natural-shoppers-community',
        buttonTitle: 'Meet our community',
        isSpecialButton: true,
    },
    {
        id: 4,
        title: 'Boost retail velocity and support product launches',
        headline: 'Increase Retail Velocity',
        content: 'Win at your key retail locations and regions using our geo-targeting capabilities. Peekage will send thousands of consumers to pick up your product at targeted locations to drive revenue, showcasing your product demand and strengthening buyer relationships. It also studies shopping trips to identify product and in-store experience improvement opportunities.',
        image: 'insights/feature4.jpg',
        component: 'FeatureItem',
        class: 'nth-reverse',
        aos: {
            content: 'fade-in',
            image: 'fade-in',
        },
        url: '/product-sampling-program',
        buttonTitle: 'Learn about Peekage product sampling program',
    },
    {
        id: 5,
        title: 'Get quick answers to product questions at scale',
        headline: 'Consumer Insights & Product Testing',
        content: 'Effortlessly test your concepts and products with consumers at scale and receive rapid feedback on your product formula, taste, packaging, pricing, positioning, and messaging, at lightning speed. Peekage streamlines recruitment, fulfillment, data collection, and analysis, allowing you to concentrate on perfecting your product.',
        image: 'insights/feature1.jpg',
        component: 'FeatureItem',
        aos: {
            content: 'fade-in',
            image: 'fade-in',
        },
        url: '/consumer-insights-company',
        buttonTitle: 'Learn about Peekage consumer insights research',
    },
    {
        id: 6,
        title: 'Collect and distribute product reviews',
        headline: 'Ratings, Reviews and Social Media UGC',
        content: 'Tell your brand story by collecting and distributing photos, videos, ratings, and reviews from authentic consumers while building shopper trust, increasing sales, and driving advocacy.',
        image: 'insights/2.jpg',
        component: 'FeatureItem',
        class: 'nth-reverse',
        aos: {
            content: 'fade-in',
            image: 'fade-in',
        },
    },
];

export default features;
