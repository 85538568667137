<template>
    <div class="empower-page">
        <Heading
            color="primary"
            class="text-center f-w-700 m-t-8 m-b-24"
            tag="p"
            size="web-md"
            style="letter-spacing: -0.5px;"
        >
            Empower Your Teams
        </Heading>

        <div class="empower-cards-top">
            <SolutionCard
                class="card-item"
                v-for="(solution, i) in solutions.slice(0, 2)"
                :key="i"
                :solution="solution"
            />
        </div>

        <div class="empower-cards h-100 m-t-20">
            <SolutionCard
                class="card-item h-100"
                v-for="(solution, i) in solutions.slice(2, 5)"
                :key="i"
                :solution="solution"
            />
        </div>
    </div>
</template>

<script>
import SolutionCard from '~/components/common/empower/empower-card';

const solutions = [
    {
        image: 'arts/Retarget.svg',
        title: 'Marketing & Brand Management ',
        description:
            'Easily launch product sampling campaigns to reach a a community of health conscious shoppers keen to test and review your products and open to switching to your products.',
        uri: '/',
    },
    {
        image: 'arts/Speed.svg',
        title: 'Shopper Marketing',
        description: 'Build momentum for product launches or support regions and retailers that are not performing by sending interested shoppers to stores to move your products off-shelves quickly.',
        uri: '/',
    },
    {
        image: 'arts/Pictogram-2.svg',
        title: 'Research & Development',
        description: "Run seamless product testing from recruitment, fullfilment, data collection, to analysis—all within a single platform with Peekage's automated in-home usage testing solution. Simplify your testing procedures and focus on gaining insights on your formula, taste, packaging, pricing, competition, and more.",
        uri: '/',
    },
    {
        image: 'arts/Research.svg',
        title: 'Product Innovation & Growth',
        description: 'Turn your new product into a top performer within your portfolio by identifying the ideal buyer persona, validating product positioning and messaging, and uncovering retail optimization and expansion opportunities.',
        uri: '/',
    },
    {
        image: 'arts/Pictogram-lg.svg',
        title: 'Business Decision Makers',
        description: 'Get answer to your business questions about market dynamics, consumer behavior, and product optimization opportunities—all without the need for in-house research expertise. With just a few clicks, access consumer insights swiftly and affordably, empowering your decision-making process and driving business success.',
        uri: '/',
    },

];

export default {
    components: {
        SolutionCard,
    },
    data() {
        return {
            solutions: [...solutions],
        };
    },
};
</script>

<style lang="scss" scoped>
.empower-page {
    .empower-cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
    }

    .empower-cards-top {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    .card-item {
        display: block;
        min-height: 230px;
    }

    @media (max-width: 768px) {
        .empower-cards,
        .empower-cards-top {
            grid-template-columns: 100%;
        }

        .card-item {
            display: block;
            height: auto;
            margin: 0 16px;
        }
    }
}
</style>
